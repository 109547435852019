<template>
  <span class="logo">
    <span class="logo_green">Revo</span>apps
  </span>
</template>

<script>
export default {
  name: 'BaseLogo'
}
</script>

<style lang="scss">
  .logo {
    font-family: $font-family-urbanist;
    color: var(--color-white);
    font-weight: 700;
    line-height: 90%;

    &_green {
      color: var(--color-accent-lime);
    }
  }
</style>
