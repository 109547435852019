<template>
  <div class="section information">
    <div class="section__container">
      <BaseTitle>Here is some information about our main <br /> services and customer support department:</BaseTitle>
      <div class="information__items">
        <div
          v-for="(info, index) in informationList"
          :key="`information__item-${index}`"
          :class="['information__item', `information__item-${index + 1}`]"
        >
          <div class="information__title" v-html="info.title"></div>
          <div class="information__description">
            {{ info.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@/components/base/BaseTitle';

export default {
  name: 'Information',
  components: {
    BaseTitle,
  },
  data: () => ({
    informationList: [
      {
        title: 'Mobile <br /> Advertising',
        description: 'We help businesses reach their target audience through various mobile advertising techniques, including in-app advertising, mobile web advertising, and mobile search advertising.',
      },
      {
        title: 'SMS <br />Marketing',
        description: 'We provide effective SMS marketing campaigns to communicate with customers, offer exclusive deals and promotions, and generate leads.',
      },
      {
        title: 'Mobile App <br />Marketing',
        description: 'Our team of experts can help businesses optimize their mobile app store presence, increase app downloads, and improve user engagement.',
      },
      {
        title: 'Mobile <br />Analytics',
        description: 'Our team helps businesses analyze user engagement, demographics, and other metrics to optimize marketing strategies.',
      },
      {
        title: 'Customer <br />Support',
        description: 'Our customer support team is available 24/7 to assist our clients with any technical issues or concerns. We provide prompt and personalized assistance to ensure smooth operations for our clients.',
      },
    ]
  }),
}
</script>

<style lang="scss">
  .information {
    background: var(--color-white);

    &__items {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: $screen-l) {
        margin-top: 60px;
        display: grid;
        grid-template-areas:
          'block1 block3 block4'
          'block2 block3 block5'
      ;
      }
    }

    &__item {
      border-radius: 16px;
      padding: 32px;

      display: flex;
      flex-direction: column;
      gap: 68px;

      &-1 {
        grid-area: block1;
        background-color: var(--color-light-lime);
      }

      &-2 {
        grid-area: block2;
        background-color: var(--color-light-pink);
      }

      &-3 {
        grid-area: block3;
        background-color: var(--color-light-purple);
        @media (min-width: $screen-l) {
          background: var(--color-light-purple) url('~@/assets/images/app.png') left bottom no-repeat;
        }
      }

      &-4 {
        grid-area: block4;
        background-color: var(--color-light-yellow);
      }

      &-5 {
        grid-area: block5;
        background-color: var(--color-light-blue);
      }

    }

    &__title {
      font-family: $font-family-urbanist;
      font-weight: 700;
      font-size: 32px;
      line-height: 110%;
    }

  }
</style>
