<template>
  <h3 class="section-title">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'BaseTitle'
}
</script>

<style lang="scss">
  .section-title {
    font-family: $font-family-urbanist;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;

    @media (min-width: $screen-l) {
      font-size: 40px;
    }
  }
</style>
