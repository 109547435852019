<template>
  <div class="section benefits">
    <div class="section__container">
      <BaseTitle>Benefits:</BaseTitle>
      <div class="benefits__items">
        <div
            v-for="(benefit, index) in benefitsList"
            :key="`benefits__item-${index}`"
            :class="['benefits__item', `benefits__item-${(index + 1)}`]"
        >
          <div class="benefits__image">
            <img :src="require(`@/assets/images/${benefit.image}.png`)" :alt="benefit.image">
          </div>
          <div class="benefits__info">
            <div class="benefits__title">
              {{ benefit.title }}
            </div>
            <div class="benefits__description">
              {{ benefit.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@/components/base/BaseTitle';

export default {
  name: 'Benefits',
  components: {
    BaseTitle,
  },
  data: () => ({
    benefitsList: [
      {
        title: 'Expertise',
        description: 'With our years of experience, we have a deep understanding of mobile marketing strategies and techniques to help businesses achieve their marketing goals.',
        image: 'expertise',
      },
      {
        title: 'Measurable results',
        description: 'Our services provide measurable results to demonstrate the effectiveness of our marketing strategies.',
        image: 'results',
      },
      {
        title: 'Personalized approach',
        description: 'Personalized approach - We tailor our services to fit the unique needs and goals of each client.',
        image: 'large',
      },
      {
        title: 'Cost-effective',
        description: 'Our services are priced competitively to provide cost-effective solutions for businesses of all sizes.Choose our technological agency of mobile marketing to elevate your business to new heights. Contact us today to learn more about our services and how we can help your business succeed in the digital world.',
        image: 'cost-effective',
      },
    ]
  }),
}
</script>

<style lang="scss">
  .benefits {
    background-color: var(--color-light-purple);

    &__items {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (min-width: $screen-l) {
        margin-top: 60px;
        display: grid;
        grid-template-columns: auto 500px;
        grid-template-areas:
          'block1 block4'
          'block2 block4'
          'block3 block4'
      ;
      }
    }

    &__image {
      min-width: 120px;
      max-width: 288px;
      img {
        max-width: 100%;
      }
      @media (min-width: $screen-l) {
        max-width: 100%;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 160%;
    }

    &__description {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
    }

    &__item {
      background: var(--color-white);
      border-radius: 16px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (min-width: $screen-l) {
        display: grid;
        grid-template-columns: auto auto;
      }

      &-1 {
        grid-area: block1;
      }

      &-2 {
        grid-area: block2;
      }

      &-3 {
        grid-area: block3;
      }

      &-4 {
        grid-area: block4;
        grid-template-columns: auto;
        grid-template-rows: auto;
      }

    }

  }
</style>
