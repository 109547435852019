<template>
  <div class="section footer">
    <div class="section__container">
      <BaseLogo />
      <span>&copy; 2023</span>
    </div>
  </div>
</template>

<script>
import BaseLogo from '@/components/base/BaseLogo';

export default {
  name: 'Footer',
  components: {
    BaseLogo,
  }
}
</script>
<style lang="scss">
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 70px;
    background-color: var(--color-accent-blue);
    color: var(--color-white);

    @media (min-width: $screen-l) {
      min-height: 160px;
    }

    .section__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
    }

    .logo {
      font-size: 32px;
    }
  }
</style>
