<template>
  <div class="section banner">
    <div class="section__wrapper">
      <div class="section__container">
        <h1><BaseLogo /></h1>
        <div class="banner__info">
          Welcome to our technological agency<br />
          of <span class="banner__info_green">mobile marketing!</span> <br />
          We specialize in providing top-notch services<br />
          to help businesses thrive in the digital world.
        </div>
        <div class="banner__star">
          <img src="@/assets/images/star.svg" alt="star"/>
        </div>
      </div>
    </div>
    <div class="banner__arrow">
      <img src="@/assets/images/arrow-down.svg"  alt="arrow-down" @click="$emit('click-scroll')"/>
    </div>
  </div>
</template>

<script>
import BaseLogo from '@/components/base/BaseLogo';

export default {
  name: 'Banner',
  components: {
    BaseLogo,
  }
}
</script>

<style lang="scss">
  .banner {
    background-color: var(--color-accent-blue);
    position: relative;

    &__info {
      margin-top: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      color: var(--color-white);

      @media (min-width: $screen-l) {
        margin-top: 100px;
      }

      &_green {
        color: var(--color-accent-lime);
        background: var(--color-info-green);
      }
    }

    &__arrow {
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      @media (min-width: $screen-l) {
        bottom: 45px;
      }

      img:hover {
        cursor: pointer;
      }
    }

    &__star {
      position: absolute;
      top: -70px;
      right: -20px;

      img {
        max-width: 88px;
      }

      @media (min-width: $screen-l) {
        top: -90px;
        right: 260px;

        img {
          max-width: 100%;
        }
      }

    }
  }

  .section__wrapper {
    display: flex;
    align-items: center;

    @media (min-width: $screen-l) {
      background: url('~@/assets/images/banner.png') right top no-repeat;
    }
  }

  .section__container {
    position: relative;
  }

  .logo {
    font-size: 60px;
    @media (min-width: $screen-l) {
      font-size: 140px;
    }
  }

</style>
