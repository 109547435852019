<template>
  <main>
    <Banner ref="banner" @click-scroll="onScrollTo"/>
    <Information ref="information" />
    <Benefits />
    <Footer />
  </main>
</template>

<script>
import Banner from '@/components/Banner';
import Information from '@/components/Information';
import Benefits from '@/components/Benefits';
import Footer from '@/components/Footer';

export default {
  name: 'App',
  components: {
    Banner,
    Information,
    Benefits,
    Footer,
  },
  methods: {
    onScrollTo() {
      const { y: yFrom } = this.$refs.banner.$el.getBoundingClientRect();
      const { y: yTo } = this.$refs.information.$el.getBoundingClientRect();

      window.scrollTo({
        top: Math.abs(yFrom) + yTo,
        behavior: "smooth"
      });
    }
  }
}
</script>
